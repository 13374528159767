/*
 * @Author: erPang
 * @Date: 2021-12-14 15:19:07
 * @Last Modified by: erPang
 * @Last Modified time:
 * http请求封装
 */
import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { createHashHistory } from 'history';
import { getToken, setToken } from './utils';

export const BASE_URL = process.env.REACT_APP_API_CONFIG;

export const http = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000,
  responseType: 'json',
});

export interface IResponse<T> {
  result: T;
  message: string;
  code: number;
}

const IncludesCode = [401, 4001];

http.interceptors.request.use((config: AxiosRequestConfig) => {
  // ! 授权信息
  config.headers['Authorization'] = getToken() || '';
  config.headers['deviceType'] = 1;
  config.validateStatus = (status) => {
    return (status >= 200 && status < 300) || IncludesCode.includes(status) || status === 400;
  };
  return config;
}, error => {
  message.error('您的网络有问题，请检查网络连接或稍后再试！');
  return Promise.reject(error);
});

http.interceptors.response.use(<T>(response: AxiosResponse<IResponse<T>>) => {
  const isDownload = response.config.url &&
    (response.config.url.indexOf('capi/periodical/download') > -1 ||
      response.config.url.indexOf('doc-convert/preview') > -1);

  if (isDownload) {
    if (response.status === 200) {
      return response;
    } else {
      message.info('当前下载数据量过多,系统已为您转入后台排队下载,稍后请前往个人中心-期刊下载记录查看', 5);
      return response;
    }
  }

  if (response.config.url && response.config.url.indexOf('qiniup.com') > -1 && response.status === 200) {
    return response;
  }
  if (response.config.responseType === 'arraybuffer' && response.status === 200) {
    return response;
  }
  // !异常错误
  if (response.status === 200 && (typeof response.data !== 'object' || !response.data)) {
    message.error('您的网络有问题，请检查网络连接或稍后再试！！');
    return Promise.reject(response);
  }
  if (response.status === 200 && response.data.code === 200) {
    return response;
  }
  // ! 无权访问接口
  if (response.status === 200 && response.data.code === 10003) {
    const history = createHashHistory();
    history.replace('/nopermission');
    return Promise.reject(response);
  }
  if (response.status === 200 && response.data.code === 10004) {
    // const history = createHashHistory();
    // history.replace('/login');
    // message.error(response.data.message);
    return Promise.reject(response);
  }
  if (response.status === 200) {
    message.error(response.data.message);
    return Promise.reject(response);
  }
  console.log(response);

  if (response.status === 401) {
    if (response.config.url) {
      const auth = response.config.url?.indexOf('capi/user/info') > -1 || response.config.url?.indexOf('capi/periodical/list') > -1;
      if (auth && !getToken()) {
        return Promise.reject(response);
      }
    }
    // ! 重定向
    setToken('');
    const history = createHashHistory();
    history.replace('/login');
    return Promise.reject(response);
  }
  if (response.status === 4001) {
    // ! 重定向
    setToken('');
    const history = createHashHistory();
    history.replace('/loginadmin');
    return Promise.reject(response);
  }
  if (response.status === 400) {
    message.error(response.data.message);
    return Promise.reject(response);
  }

  return Promise.reject(response);
}, (error: AxiosError) => {
  console.log(error.message)

  const isDownload = error.config.url &&
    (error.config.url.indexOf('capi/periodical/download') > -1 ||
      error.config.url.indexOf('doc-convert/preview') > -1);

  console.log(isDownload)

  if (isDownload) {
    message.info('当前下载数据量过多,系统已为您转入后台排队下载,稍后请前往个人中心-期刊下载记录查看', 5);
    return Promise.reject(error);
  }

  // !超时错误
  if (error.message.indexOf("download-timeout") !== -1) {
    // 下载文件超时
    message.info('当前下载数据量过多,系统已为您转入后台排队下载,稍后请前往个人中心-期刊下载记录查看', 5);
  } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    message.error('您的网络有问题，请检查网络连接或稍后再试！！！');
  }
    // else if (response.status === 400) {
    //     message.error(response.data.message);
    //     return Promise.reject(response);
  //   }
  else {
    // !未知错误
    message.error('您的网络有问题，请检查网络连接或稍后再试！！！！');
  }

  return Promise.reject(error);
});
