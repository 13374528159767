import React, { useState } from "react";
import styles from "./page-ad.module.scss";
import { useHistory } from "react-router-dom";

const PageAd: React.FC = () => {
  const history = useHistory();
  const [isShow, setIsShow] = useState<boolean>(true);

  const handelClick = () => {
    history.push("/vip");
  };
  const handelClose = () => {
    setIsShow(false);
  };

  if (isShow) {
    return (
      <div className={styles["pageAd"]} onClick={handelClick}>
        <div
          className={styles["close"]}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            handelClose();
          }}
        ></div>
      </div>
    );
  }
  return <></>;
};

export default PageAd;
