import React, { createContext, Dispatch, SetStateAction, useState, } from "react";
import { Route, Switch } from "react-router-dom";
import { useImmer } from "use-immer";
import BasicLayout from './components/layouts/basic-layout/basic-layout';
import LazyHOCV2 from "./components/lazy-componentv2";

const Index = LazyHOCV2(() => import("./views/h5/index/index"));
const Login = LazyHOCV2(() => import("./views/login/login"));
const LoginAdmin = LazyHOCV2(() => import("./views/login/login-admin"));
const Forget = LazyHOCV2(() => import("./views/h5/forget/forget"));
const Register = LazyHOCV2(() => import("./views/h5/register/register"));
const Info = LazyHOCV2(() => import("./views/h5/info/info.route"));
const User = LazyHOCV2(() => import("./views/h5/user/user-route"));
const Norm = LazyHOCV2(() => import("./views/h5/norm/norm"));
const NormInfo = LazyHOCV2(() => import("./views/h5/norm/norm-info"));
const Notice = LazyHOCV2(() => import("./views/h5/notice/notice"));
const NoticeInfo = LazyHOCV2(() => import("./views/h5/notice/notice-info"));
const Policy = LazyHOCV2(() => import("./views/h5/policy/policy"));
const PolicyInfo = LazyHOCV2(() => import("./views/h5/policy/policy-info"));
const News = LazyHOCV2(() => import("./views/h5/news/news"));
const NewsInfo = LazyHOCV2(() => import("./views/h5/news/news-info"));
const Admin = LazyHOCV2(() => import("./views/admin/AppRoute"));
const SearchInfo = LazyHOCV2(() => import("./views/h5/search/search"));
const Supplier = LazyHOCV2(() => import("./views/h5/supplier/supplier"));
const Museum = LazyHOCV2(() => import("./views/h5/museum/museum"));
const Vip = LazyHOCV2((() => import('./views/h5/vip/vip')));
const Enquiry = LazyHOCV2((() => import('./views/h5/enquiry/enquiry')));
const Books = LazyHOCV2((() => import('./views/h5/books/books')));

interface DataProps {
  gostep: number; //注册部署
  setStep: Dispatch<SetStateAction<number>>;
  change: boolean; //头部刷新
  setChange: Dispatch<SetStateAction<boolean>>;
  indexIno: {
    //首页跳转
    indexcity: string;
    indexZone: string;
    indexStreet: string;
    indexId: string; // 废弃，用year和stage
    year: string;
    stageNum: string;
  };
  setInfoIndex: Dispatch<SetStateAction<{
    indexcity: string;
    indexId: string;
    year: string;
    stageNum: string;
    indexZone: string;
    indexStreet: string;
  }>>;
  userType: 1 | 2 | 3; //1外部 2 内部 管理端 3 公司管理
  setUser: Dispatch<SetStateAction<1 | 2 | 3>>;

  tableDf: {
    indexW: number,
    nameW: number,
    dwW: number,
    jgqsW: number,
    slW: number,
    zyW: number,
    qhW: number,
    itemW: number,
    defW: number
    hidePriceTag: string,
  };
}

export const CountContext = createContext<DataProps>({} as any);
const AppRoute: React.FC = () => {
  const [gostep, setStep] = useState(1);
  const [change, setChange] = useImmer(false);
  const [indexIno, setInfoIndex] = useImmer({
    indexcity: "",
    indexId: "",
    year: "",
    stageNum: "",
    indexZone: "",
    indexStreet: "",
  });
  const [userType, setUser] = useState<1 | 2 | 3>(1);

  const [tableDf, setTableDf] = useImmer({
    indexW: 70,
    nameW: 240,
    dwW: 100,
    jgqsW: 100,
    slW: 100,
    zyW: 100,
    qhW: 100,
    itemW: 120,
    defW: 100,
    hidePriceTag: "查看价格"
  });

  return (
    <CountContext.Provider
      value={{
        gostep,
        setStep,
        change,
        setChange,
        indexIno,
        setInfoIndex,
        userType,
        setUser,
        tableDf,
      }}
    >
      <Switch>
        <Route path='/login' component={Login}></Route>
        <Route path='/loginadmin' component={LoginAdmin}></Route>
        <Route path='/admin' component={Admin}></Route>
        <Route path='/register' component={Register}></Route>
        <Route path='/forget' component={Forget}></Route>
        <BasicLayout>
          <Route exact path='/index' component={Index}></Route>
          <Route exact path='/' component={Index}></Route>
          {/* <Redirect exact from='/' to='/index'></Redirect> */}
          <Route path='/vip' component={Vip} exact></Route>
          <Route path='/enquiry' component={Enquiry} exact></Route>
          <Route path='/books' component={Books} exact></Route>
          <Route path='/info' component={Info}></Route>
          <Route path='/norm' component={Norm} exact></Route>
          <Route path='/norm/:id' component={NormInfo} exact></Route>
          <Route path='/notice' component={Notice} exact></Route>
          <Route path='/notice/:id' component={NoticeInfo} exact></Route>
          <Route path='/policy' component={Policy} exact></Route>
          <Route path='/policy/:id' component={PolicyInfo} exact></Route>
          <Route path='/news' component={News} exact></Route>
          <Route path='/news/:id' component={NewsInfo} exact></Route>
          <Route path='/user' component={User}></Route>
          <Route path='/search/:type/:key' component={SearchInfo} exact></Route>
          <Route path='/search/:type' component={SearchInfo} exact></Route>
          <Route path='/supplier' component={Supplier} exact></Route>
          <Route path='/museum' component={Museum} exact></Route>
        </BasicLayout>
      </Switch>
    </CountContext.Provider>
  );
};

export default AppRoute;
