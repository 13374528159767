import React, { useMemo } from 'react';
import classnames from 'classnames';
import styles from './empty.module.scss';
import emptyImage from './images/empty.png';

export interface EmptyProps {
  className?: string;
  /* 一级标题 */
  title: string;
  /* 二级标题 */
  subTitle: React.ReactNode;
}

const Empty: React.FC<EmptyProps> = (props) => {
  const {
    children,
    className,
    title,
    subTitle
  } = props;

  const classNames = useMemo(() => {
    return classnames(
      styles['empty'],
      className
    );
  }, [className]);

  return (
    <div className={classNames}>
      <img className={styles['empty-image']} src={emptyImage} alt=""/>
      <p className={styles['empty-title']}>{title}</p>
      <p className={styles['empty-subTitle']}>{subTitle}</p>
      <div className={styles['empty-footer']}>{children}</div>
    </div>
  );
};

export default Empty;