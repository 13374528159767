import Loadable from 'react-loadable';
import Button from './button/button';
import Empty from './empty/empty';
import Loading from './loading/loading';

const LazyLoad = (props: Loadable.LoadingComponentProps) => {
  const {
    error,
    retry
  } = props;

  if (error) {
    return (
      <Empty
        title="网络加载失败"
        subTitle="点击下方按钮重试"
      >
        <Button onClick={retry}>重试</Button>
      </Empty>
    );
  }
  return (
    <Loading/>
  );
};

const LazyHOCV2 = (loader: () => Promise<any>) => {
  return Loadable({
    loader,
    loading: LazyLoad
  });
};

export default LazyHOCV2;