import React, { useMemo } from "react";
import classnames from "classnames";
import styles from "./button.module.scss";

export interface ButtonProps {
  className?: string;
  width?: number;
  height?: number;
  icon?: string;
  type?: "default" | "white" | "line";
  block?: boolean;
  size?: "default" | "small" | "large";
  onClick?: () => void;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  danger?: boolean;
  success?: boolean;
  ghost?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    width,
    height,
    type = "default",
    size = "default",
    block = false,
    onClick,
    onMouseDown,
    disabled,
    danger,
    success,
    children,
    ghost,
  } = props;

  const classNames = useMemo(() => {
    return classnames(
      styles["button"],
      styles[`button-type__${type}`],
      styles[`button-size__${size}`],
      {
        [styles["button-disabled"]]: disabled,
        [styles["button-block"]]: block,
        [styles["button-danger"]]: danger,
        [styles["button-success"]]: success,
      },
      { [styles[`button-ghost`]]: ghost },
      className
    );
  }, [className, type, size, disabled, block, success]);

  const customStyle = useMemo(() => {
    const styleObjct: React.CSSProperties = {};
    if (width) {
      styleObjct.width = width;
    }
    if (height) {
      styleObjct.height = height;
    }
    return styleObjct;
  }, [width, height]);

  const handleClick = () => {
    if (disabled) return;
    onClick?.();
  };

  return (
    <div
      onMouseDown={onMouseDown}
      onClick={handleClick}
      className={classNames}
      style={customStyle}
    >
      <span className={styles["button-text"]}>{children}</span>
    </div>
  );
};

export default Button;
