/*
 * @Author: erPang
 * @Date: 2021-12-14 15:19:07
 * @Last Modified by: erPang
 * @Last Modified time:
 * 公共函数库
 */

import { saveAs } from 'file-saver';
import { OptionProps } from "../components/select/select";
import { http } from "./http";


// 存储的token字段
const TOKEN = process.env.REACT_APP_WEB_UUID;

/**
 * @description 获取当前token
 */
export const getToken = () => {
  if (!TOKEN) {
    throw new Error('请配置REACT_APP_WEB_UUID');
  }
  return sessionStorage.getItem(TOKEN);
};

/**
 * @description 设置当前token
 * @param value
 */
export const setToken = (value: string = '') => {
  if (!TOKEN) {
    throw new Error('请配置REACT_APP_WEB_UUID');
  }
  if (!value) {
    sessionStorage.removeItem(TOKEN);
  } else {
    sessionStorage.setItem(TOKEN, value);
  }
};

/**
 * 手机号正则式
 */
export const phoneRegExp = /^1[3-9]\d{9}$/;


/**
 * 文件是否为图片
 *
 */
export const getSuffix = (name: string) => {
  const nameSplit = name.split('/');

  return nameSplit[nameSplit.length - 1].toLocaleLowerCase();
};

export const isImage = (name: string): boolean => {
  return ['jpg', 'jpeg', 'png'].includes(getSuffix(name));
};

export const formCol = {
  labelCol: { span: 2 },
  wrapperCol: { span: 8 }
};

export const formButtonCol = {

  wrapperCol: {
    offset: 2,
    span: 2
  }
}

export const isPhone = (phone: string) => {
  return !/^(?:(?:\+|00)86)?1\d{10}$/.test(phone);
}

//模拟Id
export const getUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const getOption = (list: string[]): OptionProps[] => {
  const arr = [] as any;
  list.forEach((item) =>
    arr.push({
      value: item,
      label: item,
    })
  );
  return arr;
}

/*
* 转化金额
* number：要格式化的数字
* decimals：保留几位小数
* dec_point：小数点符号
* thousands_sep：千分位符号
* */
export const numberFormat = (
  number: number | string,
  decimals: number = 2,
  decPoint = '.',
  thousandsSep = ','
) => {
  if (!number && number !== 0) {
    return '';
  }
  const result = number.toString().replace(/[^0-9+-Ee.]/g, '');
  const n = !Number.isFinite(+result) ? 0 : +result;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = [];
  const toFixedFix = function (t: number, h: number) {
    const k = 10 ** h;
    return `${Math.ceil(t * k) / k}`;
  };

  s = (prec ? toFixedFix(n, prec) : `${Math.round(n)}`).split('.');
  const re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, `$1${sep}$2`);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
};


export const getSj = (bsj: number, sl: number) => {
  if (!sl) {
    return undefined; // 无数据
  }

  if (!bsj || !sl) return;
  return Number((bsj * (1 + sl)).toFixed(2));
}

export const downloadFile = async (
  url: string,
  name: string = '',
  data: object = {},
  ext: string = 'application/msword;charset=UTF-8'
) => {
  if (ext) {
    console.warn('此属性即将移除，请及时更新代码');
  }
  const response = await http({
    method: 'post',
    timeout: 30 * 1000,
    timeoutErrorMessage: "download-timeout2",
    url,
    responseType: 'arraybuffer',
    data
  });
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  });
  saveAs(blob, name);
};

export const getYears = () => {
  const result: { label: string; value: number }[] = [];
  for (let i = 0; i < 50; i++) {
    const curYear = i + 2000;
    result.push({
      label: `${curYear}年度`,
      value: curYear
    })
  }
  return result;
}

export const readHomeMessage = {
  get(id: string) {
    return !!localStorage.getItem(`HOME_MESSAGE_${id}`);
  },
  set(id: string) {
    localStorage.setItem(`HOME_MESSAGE_${id}`, '1');
  }
}
