import { Avatar, Tooltip } from "antd";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { CountContext } from "../../../AppRoute";
import { http, IResponse } from "../../../utils/http";
import { UserOutsideInfo } from "../../../views/h5/register/register.interface";
import UserAvatar from "./user-avatar/user-avatar";
import styles from "./user-info.module.scss";

const UserInfo: React.FC = () => {
  const history = useHistory();
  const [value, setVal] = useState<string>("");
  const { pathname } = useLocation();
  const { change } = useContext(CountContext);

  const { data: info, refetch } = useQuery(["capi/user/info", change], async () => {
    const res = await http.get<IResponse<UserOutsideInfo>>(`capi/user/info`);
    return res.data.result;
  });

  const { data: message = [] } = useQuery(['/capi/userMessage/page'], async () => {
    const res = await http.post('/capi/userMessage/page', {
      pageNum: 1,
      pageSize: 20,
      status: 0
    });
    return res.data.result.list;
  });

  useEffect(() => {
    const path = history.location.pathname;
    if (path === "/user/warn") {
      setVal("/warn");
    }
    if (path === "/user") {
      setVal("/user");
    }
  }, [change]);

  useEffect(() => {
    refetch();
  }, [pathname]);

  return (
    <div className={styles["user-info"]}>
      {/* <Tooltip placement="bottom" overlayInnerStyle={{
        padding: 0
      }} overlay={<UserNotice></UserNotice>}>
        <div
          className={classNames(styles["icon-item"], {
            [styles["icon-item-active"]]: value === "/warn",
          })}
        >
          <div
            className={classNames(styles["info-icon"])}
          ></div>
        </div>
      </Tooltip> */ }
      <Link
        to="/user/notice"
        className={ classNames(styles["icon-item"], {
          [styles["icon-item-active"]]: message.length !== 0
        }) }>
        <div
          className={ classNames(styles["info-icon"]) }
        ></div>
      </Link>
      <Tooltip
        overlayInnerStyle={ {
          padding: 0
        } }
        placement="bottom"
        overlay={ (
          <UserAvatar
            userInfo={ info as any }
            Renew={ () => {
              history.push('/vip');
            } }
          ></UserAvatar>
        ) }>
        <div
          className={ classNames(styles["avatar-item"]) }
        >
          {
            !!info?.vipType && <div className={ styles.vip }></div>
          }
          <div>
            <Avatar
              src={ info?.headImageUrl }
              size={ 40 }
            ></Avatar>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default UserInfo;
