
import { Spin } from 'antd';
import React from 'react';
import styles from './loading.module.scss';

const Loading: React.FC = () => {
  return (
    <div className={styles['loading']}>
      <Spin tip="加载中..." size="large"></Spin>
    </div>
  );
};

export default Loading;