import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./index.scss";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import "moment/locale/zh-cn";
import "antd/dist/antd.variable.min.css";
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import reportWebVitals from "./reportWebVitals";
import AppRoute from "./AppRoute";

ConfigProvider.config({
  theme: {
    primaryColor: 'rgb(255, 58, 31)'
  }
})

ReactDOM.render(
  <QueryClientProvider
    client={
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retryOnMount: false,
            cacheTime: 0,
            staleTime: 0,
            retry: false,
          },
        },
      })
    }
  >
    <ConfigProvider locale={zh_CN} >
      <HashRouter>
        <AppRoute />
      </HashRouter>
    </ConfigProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
