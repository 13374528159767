import React, { useEffect } from "react";
import { Layout, Space } from "antd";
import { useHistory } from "react-router";
import Button from "../../button/button";
import logoImage from "./images/logo@2x.png";
import groupImage from "./images/group_56@2x.png";
import styles from "./basic-layout.module.scss";
import { getToken } from "../../../utils/utils";
import UserInfo from "../user-info/user-info";
import Search from "../../search/search";
import { Link, useLocation } from "react-router-dom";
import PageAd from "../../page-ad/page-ad";

const BasicLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const layout = document.querySelector("#root");
    if (layout) {
      layout.scrollTop = 0;
    }
  }, [location.pathname]);

  return (
    <Layout className={styles.layout}>
      <PageAd></PageAd>
      <Layout.Header className={styles.header}>
        <div className={styles.headerContent}>
          <Link className={styles.headerLogo} to="/">
            <img src={logoImage} alt="" />
            <img src={groupImage} alt="" />
          </Link>
          <div className={styles.headerSearch}>
            <Search></Search>
          </div>
          {getToken() ? (
            <UserInfo />
          ) : (
            <Space>
              <Button
                className={styles["dl_button"]}
                onClick={() => {
                  history.push("/login");
                }}
              >
                登录
              </Button>
              <Button
                className={styles["zc_button"]}
                onClick={() => {
                  history.push("/register");
                }}
              >
                注册
              </Button>

              {/*<Link to="/login">登录</Link>*/}
              {/*<Link to="/register">注册1</Link>*/}
            </Space>
          )}
        </div>
      </Layout.Header>
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default BasicLayout;
