import classnames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./search.module.scss";
import Image from "./image/clear.png";
import classNames from "classnames";
import { useHistory } from 'react-router';
import { Select } from 'antd';

export interface SearchProps {
  className?: string;
  width?: number;
  height?: number;
  allowClear?: boolean;
  icon?: string;
  onSearch?: (val: string) => void;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  index?: boolean;
}

const Search: React.FC<SearchProps> = (props) => {
  const {
    className,
    width,
    height,
    onSearch,
    onMouseDown,
    children,
    // defaultValue,
    value,
    placeholder = '请输入需要查询的材料、型号名称、供应商',
    allowClear = false,
    index = false,
  } = props;
  const focus = useRef(false);
  const history = useHistory();
  const priceType = useRef<string>();
  const inputref = useRef<HTMLInputElement>(null);
  const [val, setValue] = useState("");
  const [priceDataType, setPriceDataType] = useState('1');
  const classDivNames = useMemo(() => {
    return classnames(styles["search"], className);
  }, [className]);

  const customStyle = useMemo(() => {
    const styleObjct: React.CSSProperties = {};
    if (width) {
      styleObjct.width = width;
    }
    if (height) {
      styleObjct.height = height;
    }
    return styleObjct;
  }, [width, height]);

  const handleClick = () => {
    const value = inputref.current?.value;
    history.replace(`/search/${priceType.current}/${value}`);
    value && onSearch?.(value);
  };

  useEffect(() => {
    value && setValue(value);
  }, [value]);

  const handleKeyDown = (e: any) => {
    if (focus.current) {
      const code = e?.keyCode;
      if (code === 13) {
        handleClick();
      }
    }
  };

  useEffect(() => {
    priceType.current = priceDataType;
  }, [priceDataType])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={classDivNames} style={customStyle}>
      <Select value={priceDataType} onChange={(value) => setPriceDataType(value)} bordered={false} className={styles.select}>
        <Select.Option value="1">市场价</Select.Option>
        <Select.Option value="2">信息价</Select.Option>
      </Select>
      <div className={styles["input-box"]}>
        <input
          onFocus={() => focus.current = true}
          onBlur={() => focus.current = false}
          ref={inputref}
          placeholder={placeholder}
          type='text'
          className={styles["input"]}
          // defaultValue={defaultValue}
          value={val}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {allowClear && val && (
          <img
            src={Image}
            alt=''
            className={styles["image"]}
            onClick={() => {
              setValue("");
            }}
          />
        )}
      </div>
      <div
        className={classNames(styles["button"], {
          [styles["circle"]]: index,
        })}
        onClick={handleClick}
        onMouseDown={onMouseDown}
      >
        搜索
      </div>
    </div>
  );
};
export default Search;
