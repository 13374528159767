import React from "react";
import styles from "./user-avatar.module.scss";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { setToken } from "../../../../utils/utils";
import { UserOutsideInfo } from "../../../../views/h5/register/register.interface";

interface UserAvatarProps {
  userInfo: UserOutsideInfo;
  Renew?: Function;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const history = useHistory();
  const { userInfo, Renew } = props;

  if (!userInfo) return null;
  return (
    <React.Fragment>
      <div className={styles["modal"]}>
        <div
          className={styles["list"]}
          onClick={() => {
            history.push("/user");
          }}
        >
          <div className={styles["icon1"]}></div>
          <span className={styles['text']}>{userInfo.phone}</span>
          <RightOutlined />
        </div>
        <div
          className={styles["list"]}
          onClick={() => {
            setToken("");
            history.replace("/login");
          }}
        >
          <div className={styles["icon2"]}></div>
          退出登录
        </div>
        <div className={styles["bg"]}>
          {userInfo?.vipType === 1 && (
            <React.Fragment>
              <div className={styles["title"]}>会员有效期剩余</div>
              <div className={styles["day"]}>
                <span>{userInfo.vipDays}</span>天
              </div>
            </React.Fragment>
          )}
          {userInfo?.vipType === 2 && (
            <React.Fragment>
              <div className={styles["title"]}>会员有效期剩余</div>
              <div className={styles["day"]}>
                <span>{userInfo.vipDays}</span>天
              </div>
            </React.Fragment>
          )}
          {userInfo?.vipType === 0 && (
            <React.Fragment>
              <div className={styles["title"]}>成为会员</div>
            </React.Fragment>
          )}
          <div
            className={styles["renew"]}
            onClick={() => {
              Renew?.(true);
            }}
          >
            {userInfo?.vipType === 0 ? "充值" : "续费"}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default UserAvatar;
